import Rebase from "re-base"
//import firebase from "firebase"
//The below will work for the new Firebase
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'


const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyC44C4pMqG5ulDtiVEtVGl26HkG_ycNJpU",
    authDomain: "catch-of-the-day-a0c70.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-a0c70.firebaseio.com",
   
})

const base = Rebase.createClass(firebase.database())

// This is a named export
export { firebaseApp }

// This is a default export
export default base 